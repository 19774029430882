// File: /Users/amadkata/linkportal/src/components/PublicProfile.tsx

import { collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import '../styles/PublicProfile.css';
import { db } from '../utils/firebaseConfig';

interface Link {
  id: string;
  name: string;
  url: string;
  order: number;
  active: boolean;
  icon?: string;
}

interface Header {
  id: string;
  text: string;
  order: number;
}

interface UserData {
  username: string;
  profilePicture?: string;
  bio?: string;
  backgroundColor?: string;
  socialLinks?: {
    instagram?: string;
    twitter?: string;
    facebook?: string;
    linkedin?: string;
  };
}

const PublicProfile: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [linksAndHeaders, setLinksAndHeaders] = useState<(Link | Header)[]>([]);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // حالة التحميل

  const updateCombinedData = useCallback(
    (newLinks: Link[] | null, newHeaders: Header[] | null) => {
      setLinksAndHeaders((prev) => {
        const currentLinks = newLinks || (prev.filter((item) => 'url' in item) as Link[]);
        const currentHeaders = newHeaders || (prev.filter((item) => 'text' in item) as Header[]);
        return [...currentLinks, ...currentHeaders].sort((a, b) => a.order - b.order);
      });
    },
    []
  );

  useEffect(() => {
    let unsubscribeUser: () => void = () => {};
    let unsubscribeLinks: () => void = () => {};
    let unsubscribeHeaders: () => void = () => {};

    const fetchUserData = async () => {
      try {
        if (!username) {
          console.error('Username is undefined.');
          setIsLoading(false);
          return;
        }

        const usernameDoc = await getDoc(doc(db, 'usernames', username));
        if (!usernameDoc.exists()) {
          console.error('User not found in Firestore.');
          setIsLoading(false);
          return;
        }
        const userId = usernameDoc.data()?.uid;

        unsubscribeUser = onSnapshot(doc(db, 'users', userId), (docSnap) => {
          if (docSnap.exists()) {
            setUserData(docSnap.data() as UserData);
          } else {
            console.error('User data does not exist.');
          }
        });

        const linksQuery = query(
          collection(db, 'links'),
          where('userId', '==', userId),
          where('active', '==', true)
        );
        const headersQuery = query(
          collection(db, 'headers'),
          where('userId', '==', userId)
        );

        unsubscribeLinks = onSnapshot(linksQuery, (snapshot) => {
          const fetchedLinks = snapshot.docs.map(
            (doc) => ({ id: doc.id, ...doc.data() } as Link)
          );
          updateCombinedData(fetchedLinks, null);
        });

        unsubscribeHeaders = onSnapshot(headersQuery, (snapshot) => {
          const fetchedHeaders = snapshot.docs.map(
            (doc) => ({ id: doc.id, ...doc.data() } as Header)
          );
          updateCombinedData(null, fetchedHeaders);
        });

        // بعد جلب البيانات، قم بإيقاف حالة التحميل
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setIsLoading(false);
      }
    };

    fetchUserData();

    return () => {
      unsubscribeUser();
      unsubscribeLinks();
      unsubscribeHeaders();
    };
  }, [username, updateCombinedData]);

  const handleLinkClick = (url: string) => {
    let fullUrl = url.trim();
    if (!fullUrl) {
      console.error('URL is empty.');
      return;
    }

    if (!fullUrl.match(/^https?:\/\//i)) {
      fullUrl = 'https://' + fullUrl;
    }

    try {
      new URL(fullUrl);
      window.open(fullUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Invalid URL:', fullUrl, error);
      // Optionally, you can log this error or handle it silently
    }
  };

  return (
    <div
      className="public-profile"
      style={{ backgroundColor: userData?.backgroundColor || '#4C6FFF' }}
    >
      <div className="profile-container">
        {isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
            <p className="loading-text">Loading...</p>
          </div>
        ) : (
          <>
            <div className="profile-header">
              {userData?.profilePicture ? (
                <img src={userData.profilePicture} alt="Profile" className="profile-picture" />
              ) : (
                <div className="avatar">
                  {userData?.username?.[0]?.toUpperCase() || 'U'}
                </div>
              )}
              {/* إظهار اسم المستخدم بعد تحميل البيانات */}
              <h1 className="username">@{userData?.username || 'User'}</h1>
              {userData?.bio && <p className="bio">{userData.bio}</p>}
              <div className="social-links">
                {userData?.socialLinks?.instagram && (
                  <a href={userData.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                )}
                {userData?.socialLinks?.twitter && (
                  <a href={userData.socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                    <FaTwitter />
                  </a>
                )}
                {userData?.socialLinks?.facebook && (
                  <a href={userData.socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                  </a>
                )}
                {userData?.socialLinks?.linkedin && (
                  <a href={userData.socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                )}
              </div>
            </div>
            <div className="content">
              {linksAndHeaders.map((item) =>
                'name' in item ? (
                  <button
                    key={item.id}
                    onClick={() => handleLinkClick(item.url)}
                    className="link-item"
                  >
                    {item.icon && <img src={item.icon} alt="" className="link-icon" />}
                    {item.name}
                  </button>
                ) : (
                  <div key={item.id} className="header-text">
                    {item.text}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
      <div className="linkat-logo">Powered by linkat</div>
    </div>
  );
};

export default PublicProfile;
