// File: /Users/amadkata/linkat/src/forms/SignupForm.tsx

import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useState } from 'react';
import { FaEnvelope, FaEye, FaEyeSlash, FaGoogle, FaLock, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAuthErrorMessage } from '../utils/errorHandler'; // تأكد من أن المسار صحيح
import { auth, db } from '../utils/firebaseConfig';

const SignupForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validateUsernameFormat = (username: string): string | null => {
    const usernameRegex = /^[a-zA-Z0-9_]{3,15}$/; // مثال: 3-15 حرفًا، أحرف أو أرقام أو شرطات سفلية فقط
    if (!username) return 'Username is required.';
    if (!usernameRegex.test(username)) return 'Invalid username format. It should be 3-15 characters long and contain only letters, numbers, or underscores.';
    return null;
  };

  const validateForm = (): boolean => {
    if (!username.trim() || !email.trim() || password.length < 6) {
      setError('Please fill in all fields. Password must be at least 6 characters long.');
      return false;
    }

    const usernameFormatError = validateUsernameFormat(username);
    if (usernameFormatError) {
      setError(usernameFormatError);
      return false;
    }

    return true;
  };

  const checkUsernameAvailability = async (username: string): Promise<boolean> => {
    const usernameRef = doc(db, "usernames", username);
    const docSnap = await getDoc(usernameRef);
    return !docSnap.exists();
  };

  const handleSignup = async () => {
    setError('');
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const isUsernameAvailable = await checkUsernameAvailability(username);
      if (!isUsernameAvailable) {
        setError('The username is already taken. Please choose another one.');
        setIsLoading(false);
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // تخزين بيانات المستخدم في Firestore
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        email,
        username,
        createdAt: new Date(),
        profilePicture: '',
        bio: '',
        backgroundColor: '#fff', // تعيين قيمة افتراضية لـ backgroundColor
      });

      // تخزين اسم المستخدم بشكل منفصل للبحث السريع باستخدام البريد الإلكتروني
      await setDoc(doc(db, "usernames", username), {
        uid: user.uid,
        email: email // تضمين حقل البريد الإلكتروني هنا
      });

      console.log("Signed up successfully with username: ", username);
      navigate('/dashboard');
    } catch (error: any) {
      console.error("Error during signup: ", error);
      const message = getAuthErrorMessage(error);
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    setError('');
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // التحقق مما إذا كان المستخدم موجودًا بالفعل في قاعدة البيانات
      const userDoc = await getDoc(doc(db, "users", user.uid));
      
      if (!userDoc.exists()) {
        // إذا لم يكن المستخدم موجودًا، نطلب اختيار اسم مستخدم
        const username = prompt("Please choose a username:");
        if (username) {
          const isUsernameAvailable = await checkUsernameAvailability(username);
          if (!isUsernameAvailable) {
            const error = new Error('Username is already in use.');
            (error as any).code = 'auth/username-already-in-use';
            throw error; // استخدام رمز الخطأ المناسب
          }
          
          // تخزين بيانات المستخدم في Firestore
          await setDoc(doc(db, "users", user.uid), {
            uid: user.uid,
            email: user.email,
            username,
            createdAt: new Date(),
            profilePicture: user.photoURL || '',
            bio: '',
            backgroundColor: '#fff', // تعيين قيمة افتراضية لـ backgroundColor
          });

          // تخزين اسم المستخدم بشكل منفصل للبحث السريع باستخدام البريد الإلكتروني
          await setDoc(doc(db, "usernames", username), {
            uid: user.uid,
            email: user.email // تضمين حقل البريد الإلكتروني هنا
          });
        } else {
          const error = new Error('Operation not allowed.');
          (error as any).code = 'auth/operation-not-allowed';
          throw error; // استخدام رمز الخطأ المناسب
        }
      }
      
      navigate('/dashboard');
    } catch (error: any) {
      console.error("Error during Google signup: ", error);
      const message = getAuthErrorMessage(error);
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 p-4">
      <div className="w-full max-w-md bg-white p-8 rounded-xl shadow-2xl">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create Your Account</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Join linkat and start sharing your links!
        </p>

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 my-4 rounded" role="alert">
            <p>{error}</p>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={(e) => { e.preventDefault(); handleSignup(); }}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="relative">
              <FaUser className="absolute top-3 left-3 text-gray-400" />
              <input
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm pl-10"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="relative">
              <FaEnvelope className="absolute top-3 left-3 text-gray-400" />
              <input
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm pl-10"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative">
              <FaLock className="absolute top-3 left-3 text-gray-400" />
              <input
                type={showPassword ? "text" : "password"}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm pl-10 pr-10"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button 
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {showPassword ? <FaEyeSlash className="text-gray-400" /> : <FaEye className="text-gray-400" />}
              </button>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={isLoading}
            >
              {isLoading ? 'Creating account...' : 'Sign Up'}
            </button>
          </div>
        </form>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={handleGoogleSignup}
              className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={isLoading}
            >
              <FaGoogle className="h-5 w-5 mr-2 text-red-500" />
              {isLoading ? 'Processing...' : 'Sign Up with Google'}
            </button>
          </div>
        </div>

        <p className="mt-2 text-center text-sm text-gray-600">
          Already have an account?{' '}
          <a href="/login" className="font-medium text-blue-600 hover:text-blue-500">
            Log In
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignupForm;