// File: /Users/amadkata/linkportal/src/components/Dashboard.tsx

import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import { User, getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  runTransaction,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FaCog,
  FaEye,
  FaGripVertical,
  FaLink,
  FaPlus,
  FaSignOutAlt,
  FaTrash,
  FaUser,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../styles/Dashboard.css';
import { db } from '../utils/firebaseConfig';

interface Link {
  id: string;
  name: string;
  url: string;
  description?: string;
  order: number;
  active: boolean;
  userId: string;
  createdAt: Date;
  clicks: number;
  icon?: string;
}

interface Header {
  id: string;
  text: string;
  order: number;
  userId: string;
}

interface UserData {
  uid: string;
  email: string;
  username: string;
  createdAt: Date;
  profilePicture?: string;
  bio?: string;
  backgroundColor: string;
}

const Dashboard: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);
  const [headers, setHeaders] = useState<Header[]>([]);
  const [linksAndHeaders, setLinksAndHeaders] = useState<(Link | Header)[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [newUsername, setNewUsername] = useState('');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [isUpdatingUsername, setIsUpdatingUsername] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false); // State for copy success message
  const auth = getAuth();
  const navigate = useNavigate();
  const updateTimersRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

  const unsubscribeUserDataRef = useRef<(() => void) | undefined>();
  const unsubscribeLinksRef = useRef<(() => void) | undefined>();
  const unsubscribeHeadersRef = useRef<(() => void) | undefined>();

  const fetchUserData = useCallback((userId: string) => {
    console.log('Fetching user data...');
    const userDocRef = doc(db, 'users', userId);

    const unsubscribeUserData = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data() as UserData;
        console.log('User data fetched:', data);
        setUserData(data);
        setNewUsername(data.username || '');
      } else {
        console.log('User data not found');
      }
    });

    return unsubscribeUserData;
  }, []);

  const fetchLinksAndHeaders = useCallback((userId: string) => {
    console.log('Fetching links and headers...');
    const linksQuery = query(collection(db, 'links'), where('userId', '==', userId));
    const headersQuery = query(collection(db, 'headers'), where('userId', '==', userId));

    const unsubscribeLinks = onSnapshot(linksQuery, (snapshot) => {
      const fetchedLinks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as Link));
      console.log('Links fetched:', fetchedLinks);
      setLinks(fetchedLinks);
    });

    const unsubscribeHeaders = onSnapshot(headersQuery, (snapshot) => {
      const fetchedHeaders = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as Header));
      console.log('Headers fetched:', fetchedHeaders);
      setHeaders(fetchedHeaders);
    });

    return { unsubscribeLinks, unsubscribeHeaders };
  }, []);

  useEffect(() => {
    console.log('Updating combined links and headers');
    const combined = [...links, ...headers].sort((a, b) => a.order - b.order);
    setLinksAndHeaders(combined);
  }, [links, headers]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        console.log('User logged in:', currentUser.uid);
        setUser(currentUser);

        if (unsubscribeUserDataRef.current) {
          unsubscribeUserDataRef.current();
        }
        if (unsubscribeLinksRef.current) {
          unsubscribeLinksRef.current();
        }
        if (unsubscribeHeadersRef.current) {
          unsubscribeHeadersRef.current();
        }

        unsubscribeUserDataRef.current = fetchUserData(currentUser.uid);
        const { unsubscribeLinks, unsubscribeHeaders } = fetchLinksAndHeaders(currentUser.uid);
        unsubscribeLinksRef.current = unsubscribeLinks;
        unsubscribeHeadersRef.current = unsubscribeHeaders;
      } else {
        console.log('User not logged in');
        setUser(null);

        if (unsubscribeUserDataRef.current) {
          unsubscribeUserDataRef.current();
          unsubscribeUserDataRef.current = undefined;
        }
        if (unsubscribeLinksRef.current) {
          unsubscribeLinksRef.current();
          unsubscribeLinksRef.current = undefined;
        }
        if (unsubscribeHeadersRef.current) {
          unsubscribeHeadersRef.current();
          unsubscribeHeadersRef.current = undefined;
        }

        navigate('/login');
      }
    });

    return () => {
      console.log('Cleaning up component');
      if (unsubscribeAuth) unsubscribeAuth();
      if (unsubscribeUserDataRef.current) unsubscribeUserDataRef.current();
      if (unsubscribeLinksRef.current) unsubscribeLinksRef.current();
      if (unsubscribeHeadersRef.current) unsubscribeHeadersRef.current();
    };
  }, [auth, navigate, fetchUserData, fetchLinksAndHeaders]);

  const addLink = async () => {
    if (user) {
      console.log('Adding new link');
      const newLink: Omit<Link, 'id'> = {
        name: 'New Link',
        url: '',
        description: '',
        order: linksAndHeaders.length,
        active: true,
        userId: user.uid,
        createdAt: new Date(),
        clicks: 0,
        icon: '',
      };
      try {
        const docRef = await addDoc(collection(db, 'links'), newLink);
        console.log('Link added successfully, ID:', docRef.id);
      } catch (error) {
        console.error('Error adding link:', error);
      }
    }
  };

  const addHeader = async () => {
    if (user) {
      console.log('Adding new header');
      const newHeader: Omit<Header, 'id'> = {
        text: 'New Header',
        order: linksAndHeaders.length,
        userId: user.uid,
      };
      try {
        const docRef = await addDoc(collection(db, 'headers'), newHeader);
        console.log('Header added successfully, ID:', docRef.id);
      } catch (error) {
        console.error('Error adding header:', error);
      }
    }
  };

  const updateItem = useCallback(
    (itemId: string, updatedData: Partial<Link | Header>) => {
      console.log('Updating item:', itemId, updatedData);

      // احصل على العنصر الأصلي من linksAndHeaders
      const item = linksAndHeaders.find((item) => item.id === itemId);
      if (!item) {
        console.error('Item not found:', itemId);
        return;
      }

      // حدد collectionName بناءً على نوع العنصر الأصلي
      const collectionName = 'name' in item ? 'links' : 'headers';

      // تحديث الحالة المحلية
      setLinksAndHeaders((prev) =>
        prev.map((itm) => (itm.id === itemId ? { ...itm, ...updatedData } : itm))
      );

      // تأخير التحديث إلى قاعدة البيانات لتقليل عدد الطلبات
      if (updateTimersRef.current[itemId]) {
        clearTimeout(updateTimersRef.current[itemId]);
      }

      updateTimersRef.current[itemId] = setTimeout(async () => {
        try {
          await updateDoc(doc(db, collectionName, itemId), updatedData);
          console.log(`${collectionName} updated successfully`);
        } catch (error) {
          console.error(`Error updating ${collectionName}:`, error);
        }
        delete updateTimersRef.current[itemId];
      }, 500);
    },
    [linksAndHeaders]
  );

  const deleteItem = async (itemId: string, itemType: 'link' | 'header') => {
    console.log('Deleting item:', itemId, itemType);
    const collectionName = itemType === 'link' ? 'links' : 'headers';
    try {
      await deleteDoc(doc(db, collectionName, itemId));
      console.log('Item deleted successfully');
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('Logged out successfully');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  const copylinkatURL = () => {
    const url = `${window.location.origin}/${userData?.username}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log('URL copied successfully');
        setCopySuccess(true); // Show success message
        setTimeout(() => setCopySuccess(false), 3000); // Hide after 3 seconds
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleBackgroundColorChange = async (color: string) => {
    console.log('Changing background color:', color);
    setUserData((prev) => (prev ? { ...prev, backgroundColor: color } : prev));
    if (userData && user) {
      if (updateTimersRef.current['backgroundColor']) {
        clearTimeout(updateTimersRef.current['backgroundColor']);
      }

      updateTimersRef.current['backgroundColor'] = setTimeout(async () => {
        try {
          await updateDoc(doc(db, 'users', user.uid), { backgroundColor: color });
          console.log('Background color updated successfully');
        } catch (error) {
          console.error('Error updating background color: ', error);
        }
        delete updateTimersRef.current['backgroundColor'];
      }, 500);
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    console.log('Reordering items');
    const reorderedItems = Array.from(linksAndHeaders);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    setLinksAndHeaders(updatedItems);

    try {
      const batch = writeBatch(db);
      updatedItems.forEach((item) => {
        const collectionName = 'name' in item ? 'links' : 'headers';
        const docRef = doc(db, collectionName, item.id);
        batch.update(docRef, { order: item.order });
      });
      await batch.commit();
      console.log('Order updated successfully');
    } catch (error) {
      console.error('Error updating order: ', error);
    }
  };

  const updateUsername = async () => {
    if (user && userData) {
      setIsUpdatingUsername(true);
      setUsernameError(null);
      try {
        await runTransaction(db, async (transaction) => {
          const newUsernameRef = doc(db, 'usernames', newUsername);
          const newUsernameDoc = await transaction.get(newUsernameRef);

          if (newUsernameDoc.exists() && newUsernameDoc.data()?.uid !== user.uid) {
            throw new Error('This username is already taken. Please choose another one.');
          }

          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await transaction.get(userDocRef);

          if (!userDoc.exists()) {
            throw new Error('User does not exist.');
          }

          const currentUsername = userData.username;

          if (currentUsername === newUsername) {
            throw new Error('The new username is the same as the current one.');
          }

          transaction.update(userDocRef, { username: newUsername });

          if (currentUsername) {
            const oldUsernameRef = doc(db, 'usernames', currentUsername);
            transaction.delete(oldUsernameRef);
          }

          transaction.set(newUsernameRef, {
            uid: user.uid,
            email: user.email,
          });
        });

        setIsModalOpen(false);
        setNewUsername(userData.username);
        console.log('Username updated successfully');
        alert('Username updated successfully!');
      } catch (error: any) {
        console.error('Error updating username: ', error);
        setUsernameError(
          error.message ||
            'An error occurred while updating the username. Please try again.'
        );
      } finally {
        setIsUpdatingUsername(false);
      }
    }
  };

  const cancelUsernameEdit = () => {
    setIsModalOpen(false);
    setNewUsername(userData?.username || '');
    setUsernameError(null);
  };

  useEffect(() => {
    const currentTimers = updateTimersRef.current;
    return () => {
      Object.values(currentTimers).forEach(clearTimeout);
    };
  }, []);

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <div className="top-section">
          {/* Avatar بجانب كلمة "linkat" */}
          <div className="avatar-logo-section" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <div className="avatar-logo">
              {userData?.username?.[0]?.toUpperCase() || 'U'}
            </div>
            <div className="logo">linkat</div>
            {/* إزالة السهم (Chevron) */}
          </div>
        </div>
        <nav>
          <ul>
            <li className="active">
              <FaLink className="nav-icon" /> Links
            </li>
            <li>
              <FaEye className="nav-icon" /> Appearance
            </li>
            <li>
              <FaCog className="nav-icon" /> Settings
            </li>
          </ul>
        </nav>

        {/* قائمة المستخدم المنسدلة */}
        {isMenuOpen && (
          <div className="user-profile-menu">
            <div className="user-details">
              <div className="avatar-large">
                {userData?.username?.[0]?.toUpperCase() || 'U'}
              </div>
              <span className="full-username">@{userData?.username || 'User'}</span>
            </div>
            <button className="create-new-button">Create a new linkat</button>

            <div className="menu-section">
              <h3>Account</h3>
              <ul>
                <li onClick={() => setIsModalOpen(true)}>
                  <FaUser /> My account
                </li>
              </ul>
            </div>

            <div className="menu-section">
              <h3>Appearance</h3>
              <ul>
                <li onClick={copylinkatURL}>
                  <FaLink /> Your Profile Link
                </li>
              </ul>
            </div>

            <ul className="menu-actions">
              <li onClick={handleLogout}>
                <FaSignOutAlt /> Sign out
              </li>
            </ul>
          </div>
        )}
      </aside>

      <main className="main-content">
        <header>
          <div className="linkat-url">
            🔥 Your linkat is live:{' '}
            <a href={`/${userData?.username}`}>
              {window.location.origin}/{userData?.username}
            </a>
            <button className="copy-button" onClick={copylinkatURL}>
              Copy URL
            </button>
            {/* رسالة نجاح النسخ */}
            {copySuccess && <span className="copy-success">Link copied!</span>}
          </div>
        </header>

        <section className="controls">
          <div className="buttons">
            <button onClick={addLink} className="control-button">
              <FaPlus /> Add Link
            </button>
            <button onClick={addHeader} className="control-button">
              <FaPlus /> Add Header
            </button>
          </div>
          <div className="color-picker">
            <label htmlFor="background-color">Background Color:</label>
            <input
              type="color"
              id="background-color"
              value={userData?.backgroundColor || '#ffffff'}
              onChange={(e) => handleBackgroundColorChange(e.target.value)}
            />
          </div>
        </section>

        <section className="links-list">
          {linksAndHeaders.length === 0 ? (
            <div className="empty-state">
              <div className="empty-icon">📎</div>
              <p>Show the world who you are.</p>
              <p>Add a link or header to get started.</p>
            </div>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="linksAndHeaders">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {linksAndHeaders.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className="item-drag-handle"
                              {...provided.dragHandleProps}
                            >
                              <FaGripVertical />
                            </div>
                            {'name' in item ? (
                              <div className="item-content">
                                <input
                                  type="text"
                                  value={item.name}
                                  onChange={(e) =>
                                    updateItem(item.id, {
                                      name: e.target.value,
                                    })
                                  }
                                  className="item-input"
                                  placeholder="Enter link title"
                                />
                                <input
                                  type="url"
                                  value={item.url}
                                  onChange={(e) =>
                                    updateItem(item.id, {
                                      url: e.target.value,
                                    })
                                  }
                                  placeholder="Enter URL"
                                  className="item-input"
                                />
                              </div>
                            ) : (
                              <div className="item-content">
                                <input
                                  type="text"
                                  value={item.text}
                                  onChange={(e) =>
                                    updateItem(item.id, {
                                      text: e.target.value,
                                    })
                                  }
                                  className="item-input"
                                  placeholder="Enter header text"
                                />
                              </div>
                            )}
                            <div className="item-actions">
                              {'name' in item && (
                                <>
                                  <button
                                    className={`toggle-button ${
                                      item.active ? 'active' : ''
                                    }`}
                                    onClick={() =>
                                      updateItem(item.id, {
                                        active: !item.active,
                                      })
                                    }
                                  ></button>
                                  {/* إزالة زر "Preview" واستبداله بأيقونة العين */}
                                  <FaEye
                                    className="small-eye-icon"
                                    onClick={() =>
                                      window.open(
                                        item.url.startsWith('http')
                                          ? item.url
                                          : `https://${item.url}`,
                                        '_blank'
                                      )
                                    }
                                  />
                                </>
                              )}
                              <button
                                className="delete-button"
                                onClick={() =>
                                  deleteItem(
                                    item.id,
                                    'name' in item ? 'link' : 'header'
                                  )
                                }
                              >
                                <FaTrash />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </section>
      </main>

      <aside className="preview">
        <div className="phone-mockup">
          <div
            className="phone-screen"
            style={{
              backgroundColor: userData?.backgroundColor || '#ffffff',
            }}
          >
            <div className="avatar">
              {userData?.username?.[0]?.toUpperCase() || 'U'}
            </div>
            <h2>@{userData?.username}</h2>
            <p>{userData?.bio}</p>
            <div className="preview-content">
  {linksAndHeaders
    .filter((item) => ('active' in item ? item.active : true))
    .map((item) =>
      'name' in item ? (
        <a
          key={item.id}
          href={
            item.url.startsWith('http')
              ? item.url
              : `https://${item.url}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="preview-link"
        >
          {item.name}
          {/* <FaEye className="preview-eye-icon" /> */} {/* تم إزالة أيقونة العين */}
        </a>
      ) : (
        <div key={item.id} className="preview-header">
          {item.text}
        </div>
      )
    )}
</div>

            <div className="linkat-logo">linkat</div>
          </div>
        </div>
      </aside>

      {/* Modal for "My account" */}
      {isModalOpen && (
        <div className={`modal ${isModalOpen ? 'show' : ''}`}>
          <div className="modal-content">
            <button
              className="close-button"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
            <h2>My Account</h2>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              disabled={isUpdatingUsername}
            />
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={userData?.email || ''}
              readOnly
            />
            <div className="modal-buttons">
              <button
                className="cancel-button"
                onClick={cancelUsernameEdit}
                disabled={isUpdatingUsername}
              >
                Cancel
              </button>
              <button
                className="save-button"
                onClick={updateUsername}
                disabled={isUpdatingUsername}
              >
                {isUpdatingUsername ? 'Updating...' : 'Save'}
              </button>
            </div>
            {usernameError && <p className="error-message">{usernameError}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
