// File: /Users/amadkata/linkat/src/utils/firebaseConfig.ts

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSy...",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "linkat-a4580.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "linkat-a4580",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "linkat-a4580.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "981671674265",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:981671674265:web:0911284949e253eed55308",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-5TMBT78NDP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
