// File: /Users/amadkata/linkat/src/utils/errorHandler.ts

interface FirebaseError {
    code: string;
    message: string;
  }
  
  export const getAuthErrorMessage = (error: FirebaseError | any): string => {
    if (error.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          return 'The email address is already in use. Please log in or use a different email.';
        case 'auth/invalid-email':
          return 'The email address is invalid. Please enter a valid email.';
        case 'auth/operation-not-allowed':
          return 'This operation is not allowed. Please contact support.';
        case 'auth/weak-password':
          return 'The password is too weak. It should be at least 6 characters long.';
        case 'auth/username-already-in-use':
          return 'The username is already taken. Please choose another one.';
        // Add more cases as needed
        default:
          return 'An error occurred while creating your account. Please try again.';
      }
    }
    return 'An unexpected error occurred. Please try again.';
  };