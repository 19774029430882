import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const testimonials = [
    { name: "Sarah J.", text: "linkat revolutionized how I share my content online!" },
    { name: "Mike T.", text: "User-friendly interface and fantastic features. Highly recommended!" },
    { name: "Emma L.", text: "The best tool for managing my social media presence." },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    setIsVisible(true);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div className="landing-page min-h-screen bg-gradient-to-br from-blue-100 via-white to-purple-100 text-gray-800 font-sans">
      <header className="py-6 px-4 md:px-16 flex justify-between items-center bg-white bg-opacity-80 backdrop-blur-md fixed top-0 left-0 right-0 z-10">
        <div className="logo text-3xl font-bold text-blue-600">linkat</div>
        <nav>
          <Link to="/login" className="mr-4 text-blue-600 hover:text-blue-800 transition-colors">Log In</Link>
          <Link to="/signup" className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors shadow-md hover:shadow-lg">Sign Up</Link>
        </nav>
      </header>

      <main className="mt-24 px-4 md:px-16">
        <div className={`text-center transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
          <h1 className="text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">Your Personal Link Hub</h1>
          <p className="text-xl mb-8 max-w-2xl mx-auto text-gray-600">
            Create an elegant, personalized link page for all your social media accounts and favorite websites. Share your content with style and ease.
          </p>
          <Link to="/signup" className="inline-flex items-center bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-full text-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1">
            Get Started for Free
            <span className="ml-2 text-2xl">→</span>
          </Link>
        </div>

        <div className="mt-24">
          <h2 className="text-4xl font-semibold text-center mb-12 text-gray-800">Why Choose linkat?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              emoji="🚀"
              title="Easy to Use"
              description="Intuitive interface that lets you create your page in minutes."
            />
            <FeatureCard
              emoji="🎨"
              title="Advanced Customization"
              description="Make your page unique with custom colors, layouts, and styles."
            />
            <FeatureCard
              emoji="📊"
              title="Comprehensive Analytics"
              description="Track your link performance and gain valuable insights about your audience."
            />
          </div>
        </div>

        <section className="mt-24">
          <h2 className="text-4xl font-semibold text-center mb-8 text-gray-800">What Our Users Say</h2>
          <div className="bg-white p-8 rounded-2xl shadow-xl max-w-2xl mx-auto transform hover:scale-105 transition-transform duration-300">
            <p className="text-xl italic mb-4 text-gray-600">
              "{testimonials[currentTestimonial].text}"
            </p>
            <p className="font-semibold text-blue-600">{testimonials[currentTestimonial].name}</p>
          </div>
        </section>

        <div className="text-center mt-20">
          <Link to="/signup" className="inline-flex items-center bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-full text-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1">
            Join Us Today
            <span className="ml-2 text-2xl">↓</span>
          </Link>
        </div>
      </main>

      <footer className="mt-24 bg-gray-100 py-8 px-4 md:px-16 text-center">
        <p className="text-gray-600">&copy; 2024 linkat. All rights reserved.</p>
      </footer>
    </div>
  );
};

const FeatureCard: React.FC<{ emoji: string; title: string; description: string }> = ({ emoji, title, description }) => (
  <div className="bg-white p-8 rounded-2xl shadow-lg text-center transform hover:scale-105 transition-transform duration-300">
    <div className="text-4xl mb-4">{emoji}</div>
    <h3 className="text-2xl font-semibold mb-2 text-gray-800">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

export default LandingPage;